import React from 'react'
import styled from 'styled-components'
import SupremeInfo from './SupremeInfo'

const info = [
    {name: '会社名', content: '株式会社マーチ'},
    {name: '創業', content: ' 1986年5月28日'},
    {name: '住所', content: '〒612-8292 京都市伏見区横大路朱雀11-2'},
    {name: '電話番号', content: '(075)622-5115'},
    {name: 'FAX番号', content: '(075)622-4464'},
    {name: 'Email', content: 'sales@march-japan.com'},
    {name: 'URL', content: 'https://march-japan.com/'},
    {name: '代表取締役', content: '糟野雅治'},
    {name: '資本金', content: '10,000,000円'},
]

const CompanyTable = styled.div`
    width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .intro {
        width: 100%;
        box-sizing: border-box;
        padding: 25px 20px;
        font-size: 14px;
        border-radius: 2px;
        color: var(--gray-color-1);
        background: var(--white-color-3);
        border: solid 1px #aaa;
        margin-bottom: 20px;
    }
    .row {
        color: var(--gray-color-2);
        display: grid;
        grid-template-columns: 1fr 2fr;
        .col {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 30px 10px;
        }
        border-bottom: solid 1px var(--white-color-3);
        &:first-child {
            border-top: solid 1px var(--white-color-3);
        }
    }
`

function CompanyInfo() {
    return (
        <>
        <SupremeInfo infoNum={3} />
        <CompanyTable>
            {info.map((row, index) => (
                <div className='row' key={index}>
                    <div className='col'>{row.name}</div>
                    <div className='col'>{row.content}</div>
                </div>
            ))}
        </CompanyTable>
        </>
    )
}

export default CompanyInfo
