import React from 'react'
import styled from 'styled-components'
import PageComponent from '../common/PageComponent'
import { Link } from 'gatsby'
import InstagramLogo from '../../assets/images/svg/instagram-color.svg'
import FacebookLogo from '../../assets/images/svg/facebook-square-color.svg'
import Button from '../common/Button'

const pageComponentStyle = {
    background: '#111',
}

const FooterStyle = styled.footer`
    padding: 30px 0 50px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    color: var(--white-color-1);
    position: relative;
    color: #eee;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .company-info {
        .company-info-list {
            font-family: var(--gothic);
            font-weight: 300;
            color: var(--white-color-3);
            margin: 40px 0;
            li {
                margin: 10px;
            }
        }
        .company-name {
            width: auto;
            a {
                font-family: 'Arvo', serif;
                color: #eee;
                letter-spacing: 0.01rem;
                font-size: 1.8rem;
                font-weight: 400;
                padding: 10px;
                white-space: nowrap;
                img {
                    // position: absolute;
                    height: 90px;
                }
            }
        }
        .company-contact-button {
            margin: 20px 0 20px 20px;
        }
    }
    .footer-items-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .footer-items {
            font-size: 14px;
            margin-left: 100px;
            width: auto;
            max-width: 800px;
            font-family: var(--gothic);
            font-weight: 300;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            .footer-item-title {
                color: var(--white-color-3);
                margin: 10px 0;
                font-size: 16px;
            }
            .footer-item {
                width: 120px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                a {
                    color: var(--gray-color-4);
                    line-height: 2;
                }
                &::before {
                    content: '';
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: var(--gray-color-4);
                    margin-right: 5px;
                }
                &:hover {
                    a {
                        color: #fafafa;
                    }
                    &::before {
                        background: #fafafa;
                    }
                }
            }
        }
    }
    .icons {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .icon-container {
            margin: 0 15px;
            .icon {
                width: 40px;
            }
        }
    }
    .copy-right {
        position: absolute;
        box-sizing: border-box;
        bottom: 20px;
        width: 100%;
        text-align: center;
        padding-right: 10px;
        font-size: 12px;
        color: #aaa;
    }
    @media screen and (max-width: 500px) {
        padding-top: 30px;
        .footer-items-container {
            padding: 30px 0;
            .footer-items {
                margin: 0 20px;
            }
        }
        .icons {
            justify-content: flex-start;
            padding: 30px 0;
        }
    }
`
function Footer() {
    return (
        <PageComponent styles={pageComponentStyle}>
            <FooterStyle>
                <div className='company-info'>
                    <h1 className='company-name'><Link to='/'>Supreme Technology</Link></h1>
                    <ul className='company-info-list'>
                        <li>株式会社マーチ</li>
                        <li>〒612-8292 京都市伏見区横大路朱雀11-2</li>
                        <li>TEL: (075)622-5115</li>
                        <li>FAX: (075)622-4464</li>
                        <li>URL: <a href="https://march-japan.com/">https://march-japan.com/</a></li>
                    </ul>
                    <Link to='/contact' className='company-contact-button'>
                        <Button isDarkMode={true}>
                            お問い合わせはこちら
                        </Button>
                    </Link>
                </div>
                <div className='footer-items-container'>
                    <ul className='footer-items'>
                    <div className='footer-item-title'>サイトマップ</div>
                        <li className='footer-item'><Link to='/product'>製品紹介</Link></li>
                        <li className='footer-item'><Link to='/news/1'>お知らせ</Link></li>
                        <li className='footer-item'><Link to='/company'>会社概要</Link></li>
                        <li className='footer-item'><Link to='/contact'>お問い合わせ</Link></li>
                        <li className='footer-item'><Link to='https://march-japan.com/'>コーポレートサイト</Link></li>
                    </ul>
                </div>
                <div className='icons'>
                    <div className='icon-container'>
                        <a href='/' target='_blank' rel="noreferrer">
                            <InstagramLogo className='icon'/>
                        </a>
                    </div>
                    <div className='icon-container'>
                        <a href='https://www.facebook.com/SupremeTechnologyJapan/' target='_blank' rel="noreferrer">
                            <FacebookLogo className='icon'/>
                        </a>
                    </div>
                </div>
                <p className="copy-right">© 2025 株式会社マーチ, all rights reserved.</p>
            </FooterStyle>
        </PageComponent>
    )
}

export default Footer
